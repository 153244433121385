const getBaseUrl = () => {
  if(window?.location?.origin){
      var base_url = window.location.origin;

      var apiUrl = `${base_url}/fcb/fcb-api/api`;
      return apiUrl;
  }
  else{
      return "";
  }
}

const getBaseUrlMedia = () => {
  if(window?.location?.origin){
      var base_url = window.location.origin;

      var apiUrl = `${base_url}/fcb/fcb-api/media`;
      return apiUrl;
  }
  else{
      return "";
  }
}

const getBaseUrlRoot = () => {
  if(window?.location?.origin){
      var base_url = window.location.origin;

      var apiUrl = `${base_url}/fcb/fcb-fe`;
      return apiUrl;
  }
  else{
      return "";
  }
}

const CONFIG = {
  URL: 'https://shell-api.eventqiu.com/api', //'https://fcb-api.eventqiu.com/api', //getBaseUrl(),
  URL_MEDIA: 'https://shell-api.eventqiu.com/media', //'https://fcb-api.eventqiu.com/media', //getBaseUrlMedia()
  URL_ROOT: 'https://shell.eventqiu.com' //'https://fcb.eventqiu.com' //getBaseUrlRoot()
};

// URL: "https://fcb-api.eventqiu.com/api",
// URL: "http://192.168.100.114/fcb/fcb-api/api",
export default CONFIG;
