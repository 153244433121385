import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";

const FormNameResult = (props) => {
  const [loading, setLoading] = useState(false);
  const [userAttend, setUserAttend] = useState(false);
  const [guestAttend, setGuestAttend] = useState(false);
  const [listGuest, setListGuest] = useState([]);
  const [suksesCheckinStaff, setSuksesCheckinStaff] = useState(false);
  const [suksesCheckinGuest, setSuksesCheckinGuest] = useState(false);
  const [msgCheckin, setMsgCheckin] = useState("");

  useEffect(() => {
    if (suksesCheckinStaff === true && suksesCheckinGuest === true) {
      setLoading(false);
      props.onSuccess();
    }
  }, [suksesCheckinStaff, suksesCheckinGuest]);

  useEffect(() => {
    if (props.user?.checkin_time === "0000-00-00 00:00:00") {
      setMsgCheckin("Allowed to Enter");
    } else {
      if (props.user?.checkout_time === "0000-00-00 00:00:00") {
        setMsgCheckin("Already Checked In");
      } else {
        if (
          moment(props.user?.checkin_time) > moment(props.user?.checkout_time)
        ) {
          setMsgCheckin("Already Checked In");
        } else {
          setMsgCheckin("Allowed to Reenter");
        }
      }
    }
  }, [props.user]);

  const renderGuest = () => {
    let res = [];
    let number = parseInt(props?.user?.guest);
    let i = 0;
    while (i < number) {
      res.push(i + 1);
      i++;
    }

    return res;
  };

  useEffect(() => {
    let arr = [];
    let i = 0;
    while (i < parseInt(props?.user?.guest)) {
      arr.push({
        id: i + 1,
        active: false,
        can_change: true,
      });
      i++;
    }

    let j = 0;
    while (j < parseInt(props?.user?.guest_attend)) {
      arr[j].active = true;
      arr[j].can_change = false;
      j++;
    }

    setListGuest(arr);
  }, [props?.user?.guest, props?.user?.guest_attend]);

  useEffect(() => {
    if (props?.user?.checkin_time !== "0000-00-00 00:00:00") {
      setUserAttend(true);
    }
  }, [props?.user?.checkin_time]);

  useEffect(() => {
    if (props?.user?.checkin_time_guest !== "0000-00-00 00:00:00") {
      setGuestAttend(true);
    }
  }, [props?.user?.checkin_time_guest]);

  const chooseGuest = (id) => {
    let arr = [...listGuest];
    let exist = arr.find((v) => v.id === id);

    if (exist.can_change) {
      if (exist) {
        if (exist.active) {
          exist.active = false;
        } else {
          exist.active = true;
        }
      }

      setListGuest(arr);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      id_user: props?.user?.id,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/checkin", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          // setLoading(false);
          if (response?.data.status === "SUCCESS") {
            setLoading(false);
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const checkinGeust = () => {
    if (loading) return;

    setLoading(true);
    const payload = {
      id_user: props?.user?.id,
      user_type: "guest",
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/checkin", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          // setLoading(false);
          if (response?.data.status === "SUCCESS") {
            setSuksesCheckinGuest(true);
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const checkinStaff = () => {
    if (loading) return;

    setLoading(true);
    const payload = {
      id_user: props?.user?.id,
      user_type: "staff",
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/checkin", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          // setLoading(false);
          if (response?.data.status === "SUCCESS") {
            setSuksesCheckinStaff(true);
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const chooseUserAttend = () => {
    if (props?.user?.checkin_time === "0000-00-00 00:00:00") {
      setUserAttend(!userAttend);
    }
  };

  const chooseGuestAttend = () => {
    if (props?.user?.checkin_time_guest === "0000-00-00 00:00:00") {
      setGuestAttend(!guestAttend);
    }
  };

  const checkCanSubmit = () => {
    if (props.user?.checkin_time === "0000-00-00 00:00:00") {
      return true;
    } else {
      if (props.user?.checkout_time === "0000-00-00 00:00:00") {
        return false;
      } else {
        if (
          moment(props.user?.checkin_time) > moment(props.user?.checkout_time)
        ) {
          return true;
        } else {
          return true;
        }
      }
    }
  };

  return (
    <>
      <div className="space-y-5 px-5 py-5 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
        <div className="text-center font-bold">
          <div className="text-xl mt-5">MAIN GATE</div>
        </div>

        <div
          className={`${
            msgCheckin === "Haven't Checked in" ||
            msgCheckin === "Already Checked In" 
              ? " bg-red-500"
              : " bg-green-500"
          } w-full text-center py-5 text-white font-bold rounded uppercase`}
        >
          {msgCheckin}
        </div>

        {/* <div
          onClick={() => chooseUserAttend()}
          className={`${
            userAttend ? "bg-gray-500" : "bg-blue-500"
          } cursor-pointer text-white flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded`}
        >
          <div className="text-center text-2xl font-bold ">
            {props.user?.first_name}{" "}
            {props.user?.last_name !== "" ? " " + props.user?.last_name : ""}
          </div>
          <div className="text-center text-xl font-bold">
            {props.user?.departement}
          </div>
        </div> */}

        <div
          className={`cursor-pointer text-white flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded`}
        >
          <div className="text-center text-2xl font-bold text-black">
            {props.user?.first_name}{" "}
            {props.user?.last_name !== "" ? " " + props.user?.last_name : ""}
          </div>
          <div className="text-center  font-bold text-black text-4xl mt-4">
            {props.user?.departement}
          </div>
        </div>

        <div className="flex flex-row space-x-4 w-full justify-center items-center text-center">
          {/* {listGuest?.map((item, key) => {
            return (
              <div
                onClick={() => chooseGuest(key + 1)}
                key={key}
                className={`${
                  item.active ? "bg-gray-500" : "bg-purple-500"
                } cursor-pointer  text-white font-bold px-5 py-2 rounded`}
              >
                GUEST {key + 1}
              </div>
            );
          })} */}

          {/* {props.user?.guest !== "0" && (
            <div
              onClick={() => chooseGuestAttend()}
              className={`${
                guestAttend ? "bg-gray-500" : "bg-purple-500"
              } cursor-pointer  text-white font-bold px-16 py-2 rounded`}
            >
              GUEST
            </div>
          )} */}
        </div>

        <div className="mx-auto flex justify-center items-center space-x-4">
          <button
            type="button"
            onClick={() => props.ok()}
            className={`mt-10 w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
          >
            CANCEL
          </button>

          {checkCanSubmit() && (
            <button
              type="button"
              onClick={() => onSubmit()}
              className={`mt-10 w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
            >
              {loading ? "Loading..." : "CONFIRM"}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default FormNameResult;
